import { LoadingVariant } from '@aurora/shared-client/components/common/Loading/enums';
import Loading from '@aurora/shared-client/components/common/Loading/Loading';
import type { LoadingVariantTypeAndProps } from '@aurora/shared-client/components/common/Loading/types';
import AppContext from '@aurora/shared-client/components/context/AppContext/AppContext';
import PageContext from '@aurora/shared-client/components/context/PageContext/PageContext';
import SwitchBranchContext from '@aurora/shared-client/components/context/SwitchBranchContext/SwitchBranchContext';
import TenantContext from '@aurora/shared-client/components/context/TenantContext';
import ThemeContext from '@aurora/shared-client/components/context/ThemeContext/ThemeContext';
import PageError from '@aurora/shared-client/components/error/PageError/PageError';
import usePageLoadingGlobalState from '@aurora/shared-client/helpers/ui/PageLoadingGlobalState';
import useSeoProperties from '@aurora/shared-client/components/seo/useSeoProperties';
import useEndUserRoutes from '@aurora/shared-client/routes/useEndUserRoutes';
import { LoadingSize, LoadingSpacing } from '@aurora/shared-client/types/enums';
import { EndUserPages } from '@aurora/shared-types/pages/enums';
import { pascalCase } from '@aurora/shared-utils/helpers/objects/StringHelper';
import { getCustomQuiltId } from '@aurora/shared-utils/helpers/quilts/QuiltHelper';
import { getThemeAssetURL } from '@aurora/shared-utils/helpers/theme/ThemeAssetHelper';
import UrlBuilder from '@aurora/shared-utils/helpers/urls/UrlHelper/UrlBuilder';
import type { NextSeoProps } from 'next-seo';
import { NextSeo } from 'next-seo';
import dynamic from 'next/dynamic';
import React, { useContext, useMemo } from 'react';
import endUserComponentRegistry from '../../../features/endUserComponentRegistry';
import ActionFeedback from '../../common/ActionFeedback/ActionFeedback';
import type { BreadcrumbItem } from '../../context/BreadcrumbContext/BreadcrumbContext';
import BreadcrumbContext from '../../context/BreadcrumbContext/BreadcrumbContext';
import useCachedQuilt from '../../useCachedQuilt';
import usePageTracking from '@aurora/shared-client/components/context/AnalyticsParentFrames/usePageTracking';
import useTranslation from '../../useTranslation';
import type { FirstSectionComponents } from '../Quilt/QuiltContainerRenderer';
import QuiltRenderer from '../Quilt/QuiltRenderer';

const CookieBanner = dynamic(() => import('../../common/CookieBanner/CookieBanner'), {
  ssr: false
});

enum OpenGraphTypes {
  /**
   * Article type
   */
  Article = 'article',
  /**
   * Website type
   */
  Website = 'website'
}

interface Props {
  pageId: EndUserPages;
  /**
   * NextSeo props object.
   */
  nextSeoProps?: NextSeoProps;
  /**
   * The children.
   */
  children?: React.ReactNode;
  /**
   * Callback function to modify the list of crumbs used for the current page in Desktop views.
   *
   * @callback
   * @param breadcrumbs The current breadcrumbs based on the node path.
   */
  decorateBreadcrumbs?(breadcrumbs: BreadcrumbItem[]): BreadcrumbItem[];
  /**
   * Custom Page Title.
   */
  pageTitle?: string;
  /**
   * Action components to be added to the end of the breadcrumb widget.
   */
  breadcrumbActionComponents?: React.FC<React.PropsWithChildren>[];
  /**
   * Show the loading indicator.
   */
  showLoading?: boolean;
  /**
   * The list of components to render as part of the first visible section of the quilt
   */
  componentsToRenderFirstInFirstVisibleSection?: FirstSectionComponents;

  /**
   * The namespace of the custom page, if this is a custom page.
   */
  customPageNamespace?: string;
  /**
   * The id of the custom page, if this is a custom page.
   */
  customPageId?: string;
}

/**
 * Base page, adds breadcrumb support, NextSeo, and the page quilt.
 *
 * @author Adam Ayres
 */
const BasePage: React.FC<React.PropsWithChildren<Props>> = ({
  pageId,
  customPageId,
  nextSeoProps = {},
  decorateBreadcrumbs = (breadcrumbs): BreadcrumbItem[] => breadcrumbs,
  children,
  pageTitle,
  breadcrumbActionComponents,
  showLoading,
  componentsToRenderFirstInFirstVisibleSection
}) => {
  const pageComponent = endUserComponentRegistry.getComponentIdForPage(pageId);
  const { formatMessage, loading: textLoading } = useTranslation(pageComponent);
  const [pageLoading] = usePageLoadingGlobalState('pageLoading');
  const { customOGSiteName, getCaseSensitivePath } = useSeoProperties();
  const {
    community: { id, title },
    contextNode
  } = useContext(AppContext);
  const tenant = useContext(TenantContext);
  const { theme } = useContext(ThemeContext);
  const { branchName } = useContext(SwitchBranchContext);
  const { router: endUserRoutes } = useEndUserRoutes();

  const loadingVariant: LoadingVariantTypeAndProps = {
    type: LoadingVariant.DOT,
    props: {
      size: LoadingSize.LG,
      spacing: LoadingSpacing.XL
    }
  };

  const customQuiltId = customPageId ? getCustomQuiltId(customPageId) : null;

  /** Calling aurora analytics mutation */
  usePageTracking(customQuiltId ? pascalCase(customQuiltId) : pageId);

  const { quilt, loading: quiltLoading } = useCachedQuilt(
    module,
    customQuiltId ?? endUserComponentRegistry.getModulePathForPage(pageId),
    contextNode?.id ?? id
  );

  const imageBrandUrl = useMemo(() => {
    return getThemeAssetURL(
      tenant,
      theme.id,
      theme.basics.customerLogo.imageAssetName,
      theme.basics.customerLogo.imageLastModified,
      null,
      branchName
    );
  }, [tenant, theme, branchName]);

  if (showLoading || textLoading || pageLoading || quiltLoading) {
    return <Loading variant={loadingVariant} />;
  }

  // if we have no quilt for a custom page, show a 404
  if (customPageId && !quilt) {
    return <PageError statusCode={404} />;
  }

  const { id: quiltId } = quilt;
  const templateId: string =
    quiltId !== pageId && quiltId.startsWith(pageId) ? quiltId.slice(pageId.length + 1) : null;

  /**
   * Create a default canonical url for the seo
   *
   * @returns Default Canonical URL
   */
  function defaultCanonicalUrl(): string {
    const liaURL: string = UrlBuilder.fromUrl(tenant.baseUrl).build();
    const pageUrlWithoutQueryParams = endUserRoutes.getPathWithoutQueryStrings();
    return `${liaURL}${pageUrlWithoutQueryParams}`;
  }

  /**
   * Create a title from the supplied bundle if a title was not present in the nextSeoProps
   *
   * @returns Title for the seo
   */
  function defaultSeoTitle(): string {
    return (
      pageTitle ||
      formatMessage('title', {
        communityTitle: title,
        contextNodeTitle: contextNode.title
      })
    );
  }

  /**
   * Checks if we are on following set of pages for setting brand logo in open graph
   */
  function brandLogoForSeo(): boolean {
    const brandLogoPages: Array<EndUserPages> = [
      EndUserPages.CommunityPage,
      EndUserPages.GroupHubsPage,
      EndUserPages.TagPage
    ];
    return brandLogoPages.includes(pageId);
  }

  /**
   * If there is no title provided, it computes its own
   * If there is no canonical url provided, it applies its own after removing all the query params
   *
   * @returns Settings for applying SEO to a page
   */
  function getDecoratedNextSeoProperties(): NextSeoProps {
    const seoTitle = nextSeoProps.title ?? defaultSeoTitle();
    const cardType = nextSeoProps?.twitter?.cardType || 'summary';
    const defaultCanonical = nextSeoProps.canonical ?? defaultCanonicalUrl();
    const canonical = getCaseSensitivePath(defaultCanonical);
    const articlePages: Array<EndUserPages> = [
      EndUserPages.BlogMessagePage,
      EndUserPages.TkbMessagePage
    ];
    const decoratedNextSeoProps: NextSeoProps = {
      ...nextSeoProps,
      title: seoTitle,
      canonical,
      openGraph: {
        type: articlePages.includes(pageId) ? OpenGraphTypes.Article : OpenGraphTypes.Website,
        url: canonical,
        title: seoTitle,
        description: nextSeoProps.description || '',
        site_name: customOGSiteName || '',
        ...(brandLogoForSeo() && imageBrandUrl
          ? {
              images: [
                {
                  url: imageBrandUrl
                }
              ]
            }
          : {}),
        ...nextSeoProps.openGraph
      },
      twitter: {
        cardType: cardType
      }
    };
    return decoratedNextSeoProps;
  }

  return (
    <PageContext.Provider value={{ pageId, templateId }}>
      <CookieBanner />
      <ActionFeedback />
      <BreadcrumbContext.Provider
        value={{
          decorateBreadcrumbs,
          actionComponents: breadcrumbActionComponents
        }}
      >
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <NextSeo {...getDecoratedNextSeoProperties()} />
        <QuiltRenderer
          quilt={quilt}
          componentsToRenderFirstInFirstVisibleSection={
            componentsToRenderFirstInFirstVisibleSection
          }
        />
        {children}
      </BreadcrumbContext.Provider>
    </PageContext.Provider>
  );
};

export default BasePage;
